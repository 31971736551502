import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import * as actions from "../state/actions/keymans"
import { CORP_PER_PAGE, CORPORATION_RELEVANT_INFO } from "../constants/constants"
import BreadcrumbList from "../components/common/breadcrumb-list"
import Pagination from "../components/common/pagination"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import CorporationItem from "../components/common/corporation-item"
import { Location } from "@reach/router"
import { addCommas, getQueryVariable, resolveSearchKey } from "../utils/str"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"

export const query = graphql`
  query CorpQuery($slug: String) {
    corporationsJson(fields: { slug: { eq: $slug } }) {
      id
      corporate_number
      name
      prefecture_name
      city_name
      street_number
      corporation_en_name
      corporation_furigana
      change_cause
      listing_type
      capital_stock
      amount_of_sales
      number_of_employees
      logo_path
      corporation_detail_detail
      average_age
      annual_income
      clothing_month
      source_url
      patented
      registered_design
      registered_trademark
      shikiho_stock_code
      shikiho_site_url
      shikiho_feature
      shikiho_consolidated_business
      # shikiho_profile
      shikiho_tel
      shikiho_main_office
      # shikiho_topic1_content
      # shikiho_topic2_content
      financial_statements
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    corporations: state.corporations.corporations.toIndexedSeq().toArray(),
    pageInfo: state.corporations.pageInfo_corp,
    isLoading: state.corporations.isLoadingCorps,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.corporations.isInitialized,
    corporation: state.corporation.corporation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCorporations: (data, overwrite) =>
      dispatch(actions.getCorporations(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getCorporationsById: id => dispatch(actions.getCorporationsById(id)),
  }
}

const styles = theme => ({
  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },
})

class CorporationRelevant extends React.Component {
  componentDidMount() {
    this.props.getCorporations(this.getQuery(this.props), true)
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getCorporations(this.getQuery(this.props))
    }
  }

  getQuery = (props, query = {}) => {
    let relevantQuery = {};
    const relevantPage = props.path.split("/").pop()
    const relevantProperty = relevantPage.replace('relevant_', '')
    if (props.data.corporationsJson) {
      const searchKay = resolveSearchKey(relevantProperty, props.data.corporationsJson)
      relevantQuery = {
        exclude_corporation_id: [props.data.corporationsJson.id],
        [relevantProperty]: [searchKay]
      }
      return {
        paging: 1,
        per_page: CORP_PER_PAGE,
        page: 1,
        ...getQueryVariable(props.location.search, ["page"]),
        ...relevantQuery,
        ...query,
      }
    }
  }

  render() {
    const {
      classes,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      data,
      path,
      isInitialized
    } = this.props
    const relevantPath = path.split("/").pop();
    let relevantText = CORPORATION_RELEVANT_INFO.find(item => item.value === relevantPath)
    relevantText = relevantText ? relevantText.name : '近い企業'
    if (relevantText === '他の上場企業' && (data.corporationsJson.listing_type === '未上場' || data.corporationsJson.listing_type === '上場廃止')) {
      relevantText = `他の未上場の企業`
    } else if (relevantText === '他の上場企業' && data.corporationsJson.listing_type) {
      relevantText = `他の${data.corporationsJson.listing_type}上場の企業`
    }

    const corporations =
      (!isInitialized) ||
      (isLoading && this.props.corporations && this.props.corporations.length === 0)
        ? Array.from(Array(CORP_PER_PAGE))
        : this.props.corporations

    return (
      <Layout hasFooterPopup={true}>
        {path && path.indexOf('/relevant_') !== -1 && (
          <SEO
            title={`${data.corporationsJson.name}の${relevantText}一覧`}
            description={`${data.corporationsJson.name}の${relevantText}一覧です。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
            canonical={`https://keyman-db.smart-letter.com${path}`}
          />
        )}
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                  { path: `/corporations`, text: '会社一覧' },
                  { path: `/corporations/${data.corporationsJson.id}`, text: `${data.corporationsJson.name}` },
                ]}
                current={{
                  path: path,
                  text: `${data.corporationsJson.name}の${relevantText}一覧`,
                }}
              />
              <div>
                <h2 className="title">
                  {data.corporationsJson.name}の{relevantText}一覧
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>

              {corporations.length > 0 && (
                <p className={classes.subheading}>
                  {pageInfo && pageInfo.total ? addCommas(pageInfo.total) : ' - '}
                  件の{relevantText}が見つかりました
                </p>
              )}

              {corporations.length === 0 ? (
                <div>{relevantText}がありません。</div>
              ) : (
                <div className="list">
                  {corporations.map((item, i) => (
                    <CorporationItem
                      item={item}
                      key={i}
                      showBorderBottom={i !== corporations.length - 1 || isLoading}
                    />
                  ))}
                </div>
              )}

              {corporations.length > 0 && pageInfo ? (
                <Pagination
                  currentPage={pageInfo.current_page}
                  lastPage={pageInfo.last_page}
                  perPage={CORP_PER_PAGE}
                  path={path ? path.slice(1) : ''}
                  total={pageInfo.total}
                  onChange={(data, overwrite) =>
                    this.props.getCorporations(this.getQuery(this.props, data), overwrite)
                  }
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>

            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <CorporationRelevant {...locationProps} {...props} />}
    </Location>
  ))
)
